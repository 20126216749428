
<template>
 <a-spin :spinning="loading">
    <div class="prompt">
      <div v-if="!loading">
        <a-alert type="warning">
          <template v-slot:message>
            <span v-if="isValid" v-html="$t('label.change.vpc.router.ip.prompt')" />
            <span v-if="vpcHasVpn" v-html="$t('label.vpc.has.vpn')" />
            <span v-if="!vpcHasVpn && !isValid" v-html="$t('label.cannot.change.vpc.router.ip.prompt')" />
          </template>
        </a-alert>
        <div class="list__footer">
          <a-button @click="handleClose" :disabled=" loading ">{{ $t('label.cancel') }}</a-button>
          <a-button @click="handleSubmit" type="primary" :disabled="!isValid || loading">{{ $t('label.ok') }}</a-button>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { api } from '@/api'
import Status from '@/components/widgets/Status'

export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  components: {
    Status
  },
  inject: ['parentFetchData'],
  data () {
    return {
      loading: false,
      isValid: false,
      vpcHasVpn: false,
      networkService: {}
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      var listNetworks = api('listNetworks', { id: this.resource.associatednetworkid, listall: true })
      var listPublicIpAddresses = api('listPublicIpAddresses', { vpcid: this.resource.vpcid, issourcenat: true, listall: true })

      let response = await listNetworks
      if (this.resource.vpcid && !this.resource.virtualmachineid && !this.resource.issourcenat) {
        let isValid = true
        if (isValid) {
          response = await listPublicIpAddresses
          var currentSnat = (response.listpublicipaddressesresponse.publicipaddress || [null])[0]
          if (currentSnat) {
            var listRemoteAccessVpns = api('listRemoteAccessVpns', { publicipid: currentSnat.id, listAll: true })
            response = await listRemoteAccessVpns
            var remoteAccessVpn = (response.listremoteaccessvpnsresponse.remoteaccessvpn || [null])[0]
            console.log(remoteAccessVpn)
            this.vpcHasVpn = !!remoteAccessVpn
            isValid = !this.vpcHasVpn
          }
        }
        this.isValid = isValid
      }
      this.loading = false
    },
    handleClose () {
      this.$parent.$parent.close()
    },
    waitForTaskSimple (jobId) {
      return new Promise((resolve, reject) => {
        this.$pollJob({
          jobId,
          successMethod: function (res) {
            resolve(res)
          },
          catchMethod: function () {
            reject(new Error('something bad happened'))
          }
        })
      })
    },
    async handleSubmit () {
      if (this.isValid) {
        this.loading = true
        var task = await api('vpcChangeRouterIp', { vpcid: this.resource.vpcid, ipaddressid: this.resource.id })
        await this.waitForTaskSimple(task.configuremiscresponse.jobid)
        this.handleClose()
        this.loading = false
        this.parentFetchData()
      }
    }
  }
}
</script>

<style scoped>
div.prompt {
  width: 400px;
  min-width: 400px;
}
button {
  margin: 5px;
}
</style>
